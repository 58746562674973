import React from "react"
import cn from "classnames"
import { Link } from "gatsby"
import styles from "./index.module.css"

const Footer = () => (
  <footer className={styles.root}>
    <nav className={cn("container", styles.nav)}>
      <Link to="/" className={styles.logo}>>_</Link>
      <Link to="/projekty">Projekty</Link>
      <Link to="/o-mnie">O mnie</Link>
      <Link to="/tagi">Tagi</Link>
      <Link to="/kontakt">Kontakt</Link>
    </nav>
  </footer>
);

export default Footer;
