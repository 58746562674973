import React from "react"
import Navbar from "../Navbar"
import Logo from "../Logo"
import Footer from "../Footer"
import styles from "./index.module.css"
import cn from "classnames"
import "../../index.css"
import "../../highlight.css"

const Layout = ({ children, location }) => {
  return (
    <div className={styles.root}>
      <svg className={styles.background} viewBox="0 0 24 24">
        <path
          fill="rgba(0, 0, 0, 0.01)"
          d="M8,3A2,2 0 0,0 6,5V9A2,2 0 0,1 4,11H3V13H4A2,2 0 0,1 6,15V19A2,2 0 0,0 8,21H10V19H8V14A2,2 0 0,0 6,12A2,2 0 0,0 8,10V5H10V3M16,3A2,2 0 0,1 18,5V9A2,2 0 0,0 20,11H21V13H20A2,2 0 0,0 18,15V19A2,2 0 0,1 16,21H14V19H16V14A2,2 0 0,1 18,12A2,2 0 0,1 16,10V5H14V3H16Z"
        />
      </svg>
      <header className={styles.header}>
        <div className={cn("container", styles.headerMenu)}>
          <Logo />
          <Navbar location={location} />
        </div>
      </header>
      <main className={styles.main}>
        <div className="container">{children}</div>
      </main>
      <Footer />
    </div>
  )
}

export default Layout
