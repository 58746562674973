import React from "react"
import { Link } from "gatsby"
import cn from "classnames"
import styles from "./index.module.css"

const Navbar = ({ location }) => (
  <nav className={styles.root}>
    <span>[</span>
    <span className={styles.mini}>[</span>
    <Link to="/projekty" className={cn({ [styles.active]: location.pathname === "/projekty" })}>Projekty</Link>
    <span>|</span>
    <span className={styles.mini}>|</span>
    <Link to="/o-mnie" className={cn({ [styles.active]: location.pathname === "/o-mnie" })}>O mnie</Link>
    <span className={styles.mini}>]</span>
    <span>|</span>
    <span className={styles.mini}>[</span>
    <Link to="/tagi" className={cn({ [styles.active]: location.pathname === "/tagi" })}>Tagi</Link>
    <span>|</span>
    <span className={styles.mini}>|</span>
    <Link to="/kontakt" className={cn({ [styles.active]: location.pathname === "/kontakt" })}>Kontakt</Link>
    <span className={styles.mini}>]</span>
    <span>]</span>
  </nav>
)

export default Navbar
