import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import cn from "classnames";
import { Twitter, GitHub } from "../Icons";
import styles from "./index.module.css"

const Bio = ({ hiding = false }) => {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        return (
          <div className={cn(styles.root, { [styles.hiding]: hiding })}>
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt="śłitfocia"
              className={styles.avatar}
            />
            <div>
              <div className={styles.heading}>
                Bartosz Wiśniewski
                <a href="https://github.com/pr0gramista" aria-label="GitHub">
                  <GitHub className={styles.social} />
                </a>
                <a href="https://twitter.com/pr0gramista" aria-label="Twitter">
                  <Twitter className={styles.social} />
                </a>
              </div>
              <p>
                Everything* Developer, entuzjasta technologii Google, przyjaciel Wrocław JUG, majsterkowicz i piwowar. Zwolennik prostych i pragmatycznych rozwiązań.
              </p>
            </div>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/slitfocia.jpg/" }) {
      childImageSharp {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Bio
