import React from "react"
import styles from "./index.module.css"
import { Link } from "gatsby"

const Logo = () => (
  <Link to="/" className={styles.root}>
    <span className={styles.big}>>PR0GRAMISTA<span className={styles.end}>_</span></span>
    <span className={styles.mini}>>_</span>
  </Link>
)

export default Logo
